import React from 'react'
import ReactDOM from 'react-dom'

import AnchorButton from '../../interactive/AnchorButton'
import ConfigurableDisplayList from '../../display/ConfigurableDisplayList'
import ContentFooterLink from '../../interactive/ContentFooterLink'
import File from '../../display/File'
import Tile from '../../display/Tile'

import { ContentFooter, ContentWrapper, ContentWrapperInner, TileList } from '../../layout/Wrapper'

import {
  uploadFileToS3,
  createPersonUpload,
  createPresignedUploadUrl,
  getUploadUrl,
  editPersonUpload,
  deletePersonUpload
} from '../../../modules/upload'
import { updatePageTitle } from '../../../modules/legacyUtils'
import { fetch, uuidv4, isIterable, redirectTo } from '../../../modules/utils'

const MAX_FILE_SIZE_MB = 50

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentId: null,
      dataFetched: false,
      displayIncome: false,
      deleting: null,
      editing: null,
      kindIds: {},
      pendingFiles: [],
      selectedIncomeType: null,
      showingUploadWindow: false,
      uploadKindId: null,
      uploadStatuses: [],
      uploadSubKindId: null
    }

    this.fileDisplayRef = React.createRef()
    this.fileUploadRef = React.createRef()

    this.deleteDocument = this.deleteDocument.bind(this)
    this.editDocument = this.editDocument.bind(this)
    this.handleLeavePage = this.handleLeavePage.bind(this)
    this.showAllDocuments = this.showAllDocuments.bind(this)
    this.showIncomes = this.showIncomes.bind(this)
    this.uploadsAdded = this.uploadsAdded.bind(this)
    this.uploadsInputClicked = this.uploadsInputClicked.bind(this)
    this.getStatusName = this.getStatusName.bind(this)
    this.getDocumentStatus = this.getDocumentStatus.bind(this)
    this.getIncomeStatus = this.getIncomeStatus.bind(this)
    this.generateModifiers = this.generateModifiers.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  shouldDisplayIncome() {
    const { dataFetched, displayIncome } = this.state
    const incomes = this.documentsByKind('income')
    const pendingIncomes = this.pendingFilesByKind('income')

    // This is temporary as eventually the title will be worked out automatically
    // should be `return dataFetched && displayIncome && income.length > 0`
    return dataFetched && displayIncome && (incomes.concat(pendingIncomes).length > 0 || this.updateDomToIncomes(false))
  }

  updateDomToIncomes(isIncomes = true) {
    updatePageTitle(isIncomes ? 'Income & tax paid' : 'Documents')
    if (isIncomes) {
      const { selectedIncomeType } = this.state

      let wrapper = document.createElement('div')
      wrapper.id = 'react-page-header-button'
      ReactDOM.render(
        <AnchorButton
          classes={['page-header__button']}
          onClick={() => this.addDocument('income', selectedIncomeType)}
          modifiers={['primary', 'small']}
        >
          Add new
        </AnchorButton>,
        document.getElementById('page-header').appendChild(wrapper)
      )
    } else {
      const reactButton = document.getElementById('react-page-header-button')
      reactButton && reactButton.remove()
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleLeavePage)
    this.fetchDocuments()
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleLeavePage)
  }

  handleLeavePage(e) {
    const { pendingFiles } = this.state
    if (pendingFiles && pendingFiles.length > 0) {
      e.preventDefault()
    }
  }

  fetchDocuments() {
    return new Promise(resolve => {
      fetch('GET', '/documents.json').then(data => {
        if (!data.personId || !data.bucketName) {
          // We're probably logged out without knowing it...
          window.location.reload()
        } else {
          this.setState(
            {
              bucketName: data.bucketName,
              dataFetched: true,
              documents: data.documents,
              kindIds: data.kindIds,
              personId: data.personId,
              statusData: data.statusData,
              subKindIds: data.subKindIds,
              uploadStatuses: data.uploadStatuses
            },
            () => {
              resolve()
            }
          )
        }
      })
    })
  }

  deleteDocument() {
    const { currentId, deleting, documents } = this.state
    if (deleting == currentId) return false
    this.setState({
      deleting: currentId
    })

    deletePersonUpload(currentId)
      .then(() => {
        const foundIndex = documents.findIndex(item => item.id == currentId)
        documents.splice(foundIndex, 1)

        this.setState({
          deleting: null,
          documents: documents,
          currentId: null
        })
      })
      .catch(e => {
        this.setState({ deleting: null })
        if (isIterable(e)) {
          const [message] = e
          console.error('Delete failed: ' + message)
        }
      })
  }

  editDocument() {
    const { currentId, editing, documents } = this.state
    if (editing == currentId) return false
    const name = this.fileDisplayRef.current.state.nameValue
    this.setState({
      editing: currentId
    })

    editPersonUpload(currentId, name)
      .then(doc => {
        const foundIndex = documents.findIndex(item => item.id == currentId)
        documents.splice(foundIndex, 1, doc)

        this.setState({
          currentId: null,
          documents: documents,
          editing: null
        })
      })
      .catch(e => {
        this.setState({ editing: null })

        if (isIterable(e)) {
          const [message] = e
          console.error('Edit failed: ' + message)
        }
      })
  }

  showDocument(id) {
    this.setState({
      currentId: id
    })
  }

  showIncomes(e, incomeType) {
    e && e.preventDefault()
    this.setState(
      {
        displayIncome: true,
        selectedIncomeType: incomeType
      },
      this.updateDomToIncomes
    )
  }

  showAllDocuments(e) {
    e && e.preventDefault()
    this.setState(
      {
        displayIncome: false,
        selectedIncomeType: null
      },
      () => this.updateDomToIncomes(false)
    )
  }

  addDocument(kind, subKind = null) {
    const { kindIds, showingUploadWindow, subKindIds } = this.state
    if (showingUploadWindow) return false
    this.setState(
      {
        showingUploadWindow: true,
        uploadKindId: kindIds[kind],
        uploadSubKindId: subKind ? subKindIds[subKind] : null
      },
      function () {
        this.fileUploadRef.current.click()
      }
    )
  }

  uploadsInputClicked() {
    this.setState({
      showingUploadWindow: false
    })
  }

  fileExtension(file) {
    return file.name.split('.').pop()
  }

  generateFilePreviews(files, uids) {
    const _this = this

    uids.forEach((uid, index) => {
      const file = files[index]

      if (!/(jpe?g|png|gif)$/i.test(file.name)) {
        return
      }

      const reader = new FileReader()

      reader.addEventListener(
        'load',
        function () {
          _this.updatePendingFile(uid, {
            preview: {
              imgSrc: this.result,
              modifiers: ['image', 'uploading'],
              svg: null
            }
          })
        },
        false
      )
      reader.readAsDataURL(file)
    })
  }

  addPendingFiles(files, uids, uploadKindId, uploadSubKindId = null) {
    const { pendingFiles } = this.state

    const nextPendingFiles = uids.map((uid, index) => {
      const file = files[index]
      return {
        id: uid,
        progress: {
          max: file.size,
          value: 0
        },
        kindId: uploadKindId,
        subKindId: uploadSubKindId,
        modifiers: ['disabled'],
        heading: file.name,
        microcopy: 'Uploading',
        preview: {
          svg: {
            name: 'file',
            text: this.fileExtension(file),
            modifiers: 'preview-svg'
          }
        }
      }
    })

    this.setState({ pendingFiles: pendingFiles.concat(nextPendingFiles) })
  }

  uploadFiles(files, uids, uploadKindId, uploadSubKindId = null) {
    const { bucketName, personId } = this.state

    if (!bucketName || !personId) {
      // We're probably logged out without knowing it...
      window.location.reload()
    }

    // Using reduce here allows us to execute these uploads in sequence
    // rather than in parallel (as with Promise.all). This is desireable,
    // because if the user abandons the uploads at 50%, we'll still have
    // captured 50% of their documents. It also avoids us posting parallel
    // requests to the Data API, which may cause issues with re-caching
    // documents properly:
    const docs = []
    const promisedUploads = uids.reduce((previousPromise, uid) => {
      return previousPromise
        .then(() => {
          const fileIndex = uids.indexOf(uid)
          const file = files[fileIndex]
          return this.uploadFile(file, uid, uploadKindId, uploadSubKindId)
        })
        .then(doc => docs.push(doc))
    }, Promise.resolve())

    return promisedUploads.then(() => docs)
  }

  async uploadFile(file, uid, uploadKindId, uploadSubKindId = null) {
    const { bucketName, personId } = this.state
    const fileExtension = this.fileExtension(file)
    const presignedUploadUrl = await createPresignedUploadUrl(uid, fileExtension)

    const onProgress = e => {
      return this.updatePendingFile(uid, { progress: { max: file.size, value: e.loaded } })
    }

    await uploadFileToS3(file, presignedUploadUrl, onProgress)

    return createPersonUpload({
      url: getUploadUrl(bucketName, personId, uid, fileExtension),
      original_file_name: file.name,
      upload_kind_id: uploadKindId,
      upload_sub_kind_id: uploadSubKindId
    })
  }

  addDocumentsAndClearPendingFiles(newDocuments = []) {
    const { pendingFiles } = this.state

    const filteredNewDocuments = newDocuments.filter(d => !!d)

    let nextPendingFiles = [...pendingFiles]
    filteredNewDocuments.forEach(doc => {
      if (!doc.url) {
        return rg4js('recordBreadcrumb', 'Document missing url', {
          document: doc
        })
      }

      const uid = doc.url.replace(/^.*[\\\/]/, '').split('.')[0]
      const foundIndex = nextPendingFiles.findIndex(item => item.id === uid)

      if (foundIndex === 0 && nextPendingFiles.length === 1) {
        nextPendingFiles = []
      } else {
        nextPendingFiles.splice(foundIndex, 1)
      }
    })

    this.fetchDocuments().then(() => {
      this.setState({
        pendingFiles: nextPendingFiles
      })
    })
  }

  uploadsAdded({ target: { files } }, multiple) {
    const filesArray = Array.from(files)
    let maxFileSizeReached = false

    filesArray.forEach(file => {
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        maxFileSizeReached = true
      }
    })

    if (maxFileSizeReached) {
      const message = `${
        multiple ? 'One of your documents' : 'Your document'
      } is too large! The limit is ${MAX_FILE_SIZE_MB}mb per file.`
      return alert(message)
    }

    const { uploadKindId, uploadSubKindId } = this.state
    const uids = Array.from(files).map(() => uuidv4())

    this.addPendingFiles(files, uids, uploadKindId, uploadSubKindId)
    this.generateFilePreviews(files, uids)

    this.uploadFiles(files, uids, uploadKindId, uploadSubKindId).then(docs => {
      this.addDocumentsAndClearPendingFiles(docs)
    })
  }

  documentsByKind(kind, subKind = null) {
    const { documents, kindIds, subKindIds } = this.state
    let documentsByKind = documents
      ? documents.filter(doc => doc.upload_kind_id === kindIds[kind] && doc.archived_at === null)
      : []

    if (subKind && documentsByKind.length > 0) {
      documentsByKind = documentsByKind.filter(doc => doc.upload_sub_kind_id === subKindIds[subKind])
    }

    return kind !== 'income' ? documentsByKind[0] : documentsByKind
  }

  documentById(id) {
    const { documents } = this.state
    return documents ? documents.find(doc => doc.id === id && doc.archived_at === null) : null
  }

  pendingFilesByKind(kind, subKind = null) {
    const { pendingFiles, kindIds, subKindIds } = this.state
    if (!pendingFiles) return []

    if (subKind === null) {
      return pendingFiles.filter(file => file.kindId === kindIds[kind])
    } else {
      return pendingFiles.filter(file => file.kindId === kindIds[kind] && file.subKindId === subKindIds[subKind])
    }
  }

  updatePendingFile(uid, obj) {
    const { pendingFiles } = this.state
    const file = pendingFiles.find(item => item.id === uid)
    Object.assign(file, obj)
    this.setState({
      pendingFiles: pendingFiles
    })
  }

  renderIncomes() {
    const { dataFetched, selectedIncomeType } = this.state
    if (!dataFetched) return null

    const incomes = this.documentsByKind('income', selectedIncomeType)
    const pendingIncomes = this.pendingFilesByKind('income', selectedIncomeType)

    return (
      <ConfigurableDisplayList
        list={incomes
          .map(obj =>
            Object.assign(obj.display_data, {
              onClick: e => {
                obj.editable ? this.showDocument(obj.id) : e.preventDefault()
              }
            })
          )
          .concat(pendingIncomes)}
        placeholderName="documents"
      />
    )
  }

  renderFile() {
    const { dataFetched, currentId } = this.state
    if (!dataFetched || !currentId) return null

    const currentDocument = this.documentById(currentId)

    return (
      <File
        {...currentDocument.file_data}
        onClose={() => this.showDocument(null)}
        onDelete={this.deleteDocument}
        onEdit={this.editDocument}
        ref={this.fileDisplayRef}
      />
    )
  }

  calculateProgress(kind, subKind = null) {
    const files = this.pendingFilesByKind(kind, subKind)
    const max = files.map(f => f.progress.max).reduce((total, current) => total + current)
    const value = files.map(f => f.progress.value).reduce((total, current) => total + current)
    return { value, max }
  }

  uploadingLabel(count) {
    return `Uploading ${count} ${count > 1 ? 'files' : 'file'}...`
  }

  getIncomeStatus(incomes, pendingIncomes) {
    return {
      label: pendingIncomes.length > 0 ? this.uploadingLabel(pendingIncomes.length) : `${incomes.length} uploaded`,
      message: null,
      modifier: pendingIncomes.length > 0 ? 'info' : incomes.length > 0 ? 'positive' : 'negative'
    }
  }

  getStatusName(document) {
    if (!document) {
      return null
    }
    const status = this.state.uploadStatuses.find(s => s.id === document?.upload_status_id)
    return status['name']
  }

  getDocumentStatus(document, hasPendingDocument, pendingDocuments) {
    const statusName = this.getStatusName(document)

    if (hasPendingDocument) {
      return {
        label: this.uploadingLabel(pendingDocuments.length),
        message: null,
        modifier: 'info'
      }
    }

    if (!document) {
      return {
        label: 'Not uploaded',
        message: null,
        modifier: 'negative'
      }
    }

    const setData = (label, message, modifier) => {
      return {
        key: document.id,
        label: label,
        message: message,
        modifier: modifier
      }
    }

    const getStatusData = () => {
      const { statusData } = this.state

      switch (statusName) {
        case 'not checked':
          return setData(
            'In Review',
            'Your document has been received, ready to be reviewed by your Tax Specialist.',
            'positive'
          )
        case 'approved':
          return setData('Approved', 'Your document has been received and approved.', 'positive')
        case 'failed':
          return setData('Expired', 'Your document is no longer valid. Please upload a new version.', 'negative')
        case 'review':
          return setData('Required', statusData.proof_of_address.text, 'warning')
      }
    }
    return getStatusData()
  }

  renderTileAction(document, hasPendingDocument, selector, alt) {
    if (hasPendingDocument) {
      return null
    } else if (!document || document?.creatable) {
      return {
        onClick: () => this.addDocument(selector),
        imgAlt: alt,
        imgName: 'plusWhite'
      }
    }
  }

  generateModifiers(document, hasPendingDocument) {
    const failed = this.getStatusName(document) === 'failed'

    if (!document || failed || (!document?.editable && document?.creatable)) {
      return ''
    }

    if (!document?.editable || hasPendingDocument) {
      return 'disabled'
    }
  }

  handleOnClick(document, selector) {
    document && document?.editable ? this.showDocument(document?.id) : this.addDocument(selector)
  }

  renderTiles() {
    const { dataFetched } = this.state
    if (!dataFetched) return null

    const photoId = this.documentsByKind('photoId')
    const proofOfAddress = this.documentsByKind('proofOfAddress')
    const incomes = this.documentsByKind('income', 'incomeDocument')
    const payslips = this.documentsByKind('income', 'payslip')

    const pendingPhotoIds = this.pendingFilesByKind('photoId')
    const pendingProofOfAddresses = this.pendingFilesByKind('proofOfAddress')
    const pendingIncomes = this.pendingFilesByKind('income', 'incomeDocument')
    const pendingPayslips = this.pendingFilesByKind('income', 'payslip')

    const hasPendingPhotoId = pendingPhotoIds.length == 1
    const hasPendingProofOfAddress = pendingProofOfAddresses.length == 1

    return (
      <TileList modifiers={['4-col', '4-col-align-top']}>
        <Tile
          className="photo-id-tile"
          heading="Photo ID"
          text="Driving licence or passport."
          onClick={() => this.handleOnClick(photoId, 'photoId')}
          modifiers={this.generateModifiers(photoId, hasPendingPhotoId)}
          status={this.getDocumentStatus(photoId, hasPendingPhotoId, pendingPhotoIds)}
          progress={hasPendingPhotoId ? this.calculateProgress('photoId') : null}
          icon={{
            alt: 'Photo ID',
            name: 'man'
          }}
          tileAction={this.renderTileAction(photoId, hasPendingPhotoId, 'photoId', 'Add Photo ID')}
        />
        <Tile
          className="proof-of-address-tile"
          heading="Proof of address"
          text="Utility bill or bank statement."
          onClick={() => this.handleOnClick(proofOfAddress, 'proofOfAddress')}
          modifiers={this.generateModifiers(proofOfAddress, hasPendingProofOfAddress)}
          status={this.getDocumentStatus(proofOfAddress, hasPendingProofOfAddress, pendingProofOfAddresses)}
          progress={hasPendingProofOfAddress ? this.calculateProgress('proofOfAddress') : null}
          icon={{
            alt: 'Proof of address',
            name: 'home'
          }}
          tileAction={this.renderTileAction(
            proofOfAddress,
            hasPendingProofOfAddress,
            'proofOfAddress',
            'Add Proof of address'
          )}
        />
        <Tile
          className="incomes-tile"
          heading="Income & tax paid"
          text="P60s or P45s."
          onClick={e => (incomes.length + pendingIncomes.length > 0 ? this.showIncomes(e, 'incomeDocument') : null)}
          modifiers={incomes.length + pendingIncomes.length == 0 ? 'inactive-link' : null}
          icon={{
            alt: 'Income & tax paid',
            name: 'letter'
          }}
          status={this.getIncomeStatus(incomes, pendingIncomes)}
          progress={pendingIncomes.length > 0 ? this.calculateProgress('income', 'incomeDocument') : null}
          tileAction={{
            onClick: () => this.addDocument('income', 'incomeDocument'),
            imgAlt: 'Add income & tax paid',
            imgName: 'plusWhite'
          }}
        />
        <Tile
          className="payslips-tile"
          heading="Payslips"
          onClick={e => (payslips.length + pendingPayslips.length > 0 ? this.showIncomes(e, 'payslip') : null)}
          modifiers={payslips.length + pendingPayslips.length == 0 ? 'inactive-link' : null}
          icon={{
            alt: 'Payslips',
            name: 'letter'
          }}
          status={this.getIncomeStatus(payslips, pendingPayslips)}
          progress={pendingPayslips.length > 0 ? this.calculateProgress('income', 'payslip') : null}
          tileAction={{
            onClick: () => this.addDocument('income', 'payslip'),
            imgAlt: 'Add payslips',
            imgName: 'plusWhite'
          }}
        />
      </TileList>
    )
  }

  render() {
    const { currentId, kindIds, uploadKindId } = this.state
    const multiple = uploadKindId && kindIds && uploadKindId === kindIds['income']
    return (
      <ContentWrapper>
        <ContentWrapperInner modifiers={this.shouldDisplayIncome() ? 'items' : null}>
          <input
            ref={this.fileUploadRef}
            className="hidden"
            type="file"
            multiple={multiple}
            onChange={e => this.uploadsAdded(e, multiple)}
            onClick={this.uploadsInputClicked}
            accept="
              image/png,
              image/jpeg,
              image/webp,
              image/heic,
              image/heif,
              application/pdf,
              text/html,
              application/vnd.ms-excel,
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
              application/rtf,
              application/msword,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          {this.shouldDisplayIncome() ? this.renderIncomes() : this.renderTiles()}
          {currentId && this.renderFile()}
        </ContentWrapperInner>
        <ContentFooter>
          <ContentFooterLink onClick={this.shouldDisplayIncome() ? this.showAllDocuments : () => redirectTo('/')}>
            {`Back to ${this.shouldDisplayIncome() ? 'Documents' : 'Home'}`}
          </ContentFooterLink>
        </ContentFooter>
      </ContentWrapper>
    )
  }
}

export default Index

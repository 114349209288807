import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import ExpenseInput from './ExpenseInput'
import Question from './Question'
import CheckBoxGroupInput from './CheckBoxGroupInput'
import ButtonGroupItem from './ButtonGroupItem'

import { flatten, includes, pluck, toggleInclusion, snakeCase } from '../../modules/utils'
import { updatePageTitle } from '../../modules/legacyUtils'
import {
  foodChargeEligibleExpenses,
  locationIdsWithFoodChargeExpenses,
  frequenciesForExpenseKind,
  uniqueExpenseKindNames,
  expenseGroupsForExpenseKindName,
  locationsForExpenseGroup,
  expenseKindNamesWithExpenses,
  expenseKindForExpenseGroupAndKindName,
  expensesForKindAndLocation
} from '../../modules/expense'
import { ButtonGroup, ContentFooter, ContentWrapper, ContentWrapperInner, FormGroup } from '../layout/Wrapper'
import Helper from '../display/Helper'
import HelperTrigger from '../display/HelperTrigger'

import helperTextData from '../../data/helperTextData.json'

function ExpenseKindWorkplaceExpenseInputs({
  dailyFoodChargeLocationIds,
  locations,
  expenseGroups,
  expenseKindName,
  expenses,
  expenseKinds,
  expenseFrequencies,
  onAdd,
  onUpdate,
  onRemove
}) {
  return (
    <FormGroup>
      {expenseGroups.map((expenseGroup, expenseGroupIndex) => {
        const expenseGroupLocations = locations[expenseGroupIndex]
        const visible = !!expenseGroupLocations.length
        const expenseKind = expenseKindForExpenseGroupAndKindName(expenseKinds, expenseGroup, expenseKindName)
        const helperText = helperTextData.expenses[snakeCase(expenseGroup.code)]?.workplace[snakeCase(expenseKindName)]

        return (
          visible && (
            <Fragment key={`expense-group-${expenseGroupIndex}`}>
              {helperText && <Helper id={`helper-workplace-expenses-${expenseKindName}`} body={helperText} />}

              <Question
                heading={expenseKindName}
                headingContent={
                  helperText && (
                    <HelperTrigger
                      helperId={`helper-workplace-expenses-${expenseKindName}`}
                      eventLabel={`the '${expenseKindName}' question on the Workplace Expense Form`}
                    />
                  )
                }
              />
              <h3 className="question__subheading">{expenseGroup.heading}</h3>
              {expenseGroupLocations.map((location, locationIndex) => {
                const locationExpenses = expensesForKindAndLocation(expenses, expenseKind, location)
                const frequencies = frequenciesForExpenseKind(expenseKind, expenseFrequencies)
                const previousExpanded = !!(
                  locationIndex &&
                  expensesForKindAndLocation(expenses, expenseKind, expenseGroupLocations[locationIndex - 1]).length > 1
                )

                // Daily food charges can only be applied to MOD meals:
                const dailyFoodCharge =
                  includes(dailyFoodChargeLocationIds, location.id) &&
                  expenseKind.name === 'Meals' &&
                  expenseGroup.code === 'MOD'

                return (
                  <ExpenseInput
                    dailyFoodCharge={dailyFoodCharge}
                    expenses={locationExpenses}
                    key={`location-${location.id}`}
                    expenseKindId={expenseKind.id}
                    first={!locationIndex || previousExpanded}
                    last={locationIndex === expenseGroupLocations.length - 1}
                    personLocationId={location.id}
                    onAdd={onAdd}
                    onUpdate={onUpdate}
                    onRemove={onRemove}
                    frequencies={frequencies}
                    title={location.display_data.heading}
                    subtitle={location.display_data.label}
                  />
                )
              })}
            </Fragment>
          )
        )
      })}
    </FormGroup>
  )
}

export default function WorkplaceExpenseForm({
  employments,
  expenses,
  locations,
  expenseKinds,
  expenseGroups,
  expenseFrequencies,
  onAdd,
  onUpdate,
  onRemove,
  onSubmit,
  onCancel,
  onToggleKind
}) {
  updatePageTitle('Workplace expenses')

  const [visibleKindNames, setVisibleKindNames] = useState([])
  const [dailyFoodChargeLocationIds, setDailyFoodChargeLocationIds] = useState([])

  const allExpenseKindNames = uniqueExpenseKindNames(expenseKinds)
  const renderableData = []
  allExpenseKindNames.forEach(expenseKindName => {
    const renderableExpenseGroups = expenseGroupsForExpenseKindName(expenseKinds, expenseGroups, expenseKindName)

    const renderableLocations = renderableExpenseGroups.map(expenseGroup =>
      locationsForExpenseGroup(locations, employments, expenseGroup)
    )

    const visible = includes(visibleKindNames, expenseKindName)
    const hasLocations = !!renderableLocations.find(cl => cl.length)

    if (hasLocations) {
      renderableData.push({
        expenseKindName,
        visible,
        renderableExpenseGroups,
        renderableLocations: hasLocations ? renderableLocations : null
      })
    }
  })

  const expenseKindOptions = renderableData.map(({ expenseKindName }) => ({
    label: expenseKindName,
    value: expenseKindName,
    checked: includes(visibleKindNames, expenseKindName)
  }))

  const dailyFoodChargeOptions = flatten(pluck(locations, 'data'))
    .filter(l => l.ministry_of_defence)
    .map(l => ({
      label: l.display_data.heading,
      value: l.id.toString(),
      checked: includes(dailyFoodChargeLocationIds, l.id)
    }))

  const toggleVisibleKindName = kindName => {
    setVisibleKindNames(toggleInclusion(visibleKindNames, kindName))
  }

  const modExpenseGroupId = expenseGroups.find(eg => eg.code === 'MOD')?.id
  const modMealsExpenseKindId = expenseKinds.find(
    k => k.expense_group_id === modExpenseGroupId && k.name === 'Meals'
  )?.id
  const handleDailyFoodChargeToggle = (locationId, adding) => {
    locationId = parseInt(locationId, 10)

    const expensesToUpdate = foodChargeEligibleExpenses(expenses, modMealsExpenseKindId, locationId)

    const updatedExpenses = expensesToUpdate.map(e => Object.assign({}, e, { daily_food_charge: adding }))

    onUpdate(updatedExpenses)
    setDailyFoodChargeLocationIds(toggleInclusion(dailyFoodChargeLocationIds, locationId))
  }

  useEffect(() => {
    onToggleKind()
  }, [visibleKindNames])

  useEffect(() => {
    const nextVisibleExpenseKindNames = expenseKindNamesWithExpenses(expenses, expenseKinds)
    if (nextVisibleExpenseKindNames.length) {
      setVisibleKindNames(nextVisibleExpenseKindNames)
    }
  }, [expenses, expenseKinds])

  useEffect(() => {
    if (!dailyFoodChargeLocationIds.length) {
      setDailyFoodChargeLocationIds(locationIdsWithFoodChargeExpenses(expenses))
    }
  }, [expenses])

  const visibleRenderableData = renderableData.filter(d => d.visible)

  return (
    <ContentWrapper modifiers="small">
      <ContentWrapperInner modifiers="longform">
        <FormGroup>
          <Question heading="Type" text="Did you pay for any of the following at the locations you worked at?" />
          <CheckBoxGroupInput
            disabled={false}
            modifiers="columns"
            onChange={toggleVisibleKindName}
            options={expenseKindOptions}
          />
        </FormGroup>
        <hr />
        {visibleRenderableData.map((data, index) => {
          const { expenseKindName, renderableExpenseGroups, renderableLocations } = data

          const showBreak = index !== visibleRenderableData.length - 1

          return (
            <Fragment key={index}>
              <ExpenseKindWorkplaceExpenseInputs
                dailyFoodChargeLocationIds={dailyFoodChargeLocationIds}
                locations={renderableLocations}
                expenseGroups={renderableExpenseGroups}
                expenseKindName={expenseKindName}
                expenses={expenses}
                expenseKinds={expenseKinds}
                expenseFrequencies={expenseFrequencies}
                onAdd={onAdd}
                onUpdate={onUpdate}
                onRemove={onRemove}
              />
              {showBreak && <hr />}
            </Fragment>
          )
        })}
        {!!dailyFoodChargeOptions.length && (
          <Fragment>
            <hr />
            <FormGroup>
              <Helper
                id={`helper-workplace-expenses-daily-food-charge`}
                body={helperTextData.expenses.mod.general.daily_food_charge}
                modifiers={[]}
              />
              <Question
                heading="Daily Food Charge"
                text="Which bases did you pay a Daily Food Charge at?"
                headingContent={
                  <HelperTrigger
                    helperId={`helper-workplace-expenses-daily-food-charge`}
                    eventLabel="the 'daily food charge' question on the Workplace Expense Form"
                  />
                }
              />
              <CheckBoxGroupInput
                disabled={false}
                modifiers="columns"
                onChange={handleDailyFoodChargeToggle}
                options={dailyFoodChargeOptions}
              />
            </FormGroup>
          </Fragment>
        )}
      </ContentWrapperInner>
      <ContentFooter modifiers="actions">
        <ButtonGroup modifiers="spread">
          <ButtonGroupItem buttonModifiers="default" element="anchor" href="#" onClick={onCancel}>
            Cancel
          </ButtonGroupItem>
          <ButtonGroupItem buttonModifiers="primary" element="anchor" href="#" onClick={onSubmit}>
            Save expenses
          </ButtonGroupItem>
        </ButtonGroup>
      </ContentFooter>
    </ContentWrapper>
  )
}

WorkplaceExpenseForm.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  employments: PropTypes.arrayOf(PropTypes.object).isRequired,
  expenseKinds: PropTypes.arrayOf(PropTypes.object).isRequired,
  expenseGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  expenseFrequencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onToggleKind: PropTypes.func
}

WorkplaceExpenseForm.defaultProps = {
  onToggleKind: () => {}
}

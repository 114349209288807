import React, { Fragment, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { updatePageTitle } from '../../../modules/legacyUtils'
import { generateDisplayListActions } from '../../../modules/timelines'

import Lead from '../../display/Lead'
import ConfigurableDisplayList from '../../display/ConfigurableDisplayList'
import ActionHeader from '../../interactive/ActionHeader'
import { BulletList } from 'react-content-loader'

import helperTextData from '../../../data/helperTextData.json'

export default function AddressesIndex({ addresses, startDate, lead, gaps }) {
  useEffect(() => {
    updatePageTitle('Addresses')
  }, [])

  const resourcesLoaded = !!addresses
  const showAddAddressButton = resourcesLoaded && !!addresses.length
  const list = useMemo(() => generateDisplayListActions(addresses, gaps, 'preview'), [addresses, gaps])

  return (
    <Fragment>
      <Lead dangerouslySetInnerHTML={{ __html: lead }} helperText={helperTextData.addresses.where_you_lived} />
      <hr />
      {resourcesLoaded ? (
        <>
          <ActionHeader text={`${startDate.format('MMMM YYYY')} - Now`}>
            {showAddAddressButton && (
              <a href="/addresses/new" className="button button--mini button--default">
                Add an address
              </a>
            )}
          </ActionHeader>
          <ConfigurableDisplayList list={list} placeholderName="addresses" small={true} />
        </>
      ) : (
        <BulletList style={{ width: '66.6%' }} />
      )}
    </Fragment>
  )
}

AddressesIndex.propTypes = {
  lead: PropTypes.string.isRequired,
  addresses: PropTypes.arrayOf(PropTypes.object),
  startDate: PropTypes.object.isRequired,
  gaps: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.object,
      endDate: PropTypes.object
    })
  ).isRequired
}

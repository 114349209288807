import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ExpenseInput from './ExpenseInput'
import Question from './Question'
import ButtonGroupItem from './ButtonGroupItem'

import { frequenciesForExpenseKind } from '../../modules/expense'
import { updatePageTitle } from '../../modules/legacyUtils'
import { snakeCase } from '../../modules/utils'
import { ButtonGroup, ContentFooter, ContentWrapper, ContentWrapperInner, FormGroup } from '../layout/Wrapper'

import Helper from '../display/Helper'
import HelperTrigger from '../display/HelperTrigger'
import helperTextData from '../../data/helperTextData.json'

export default function GeneralExpenseForm({
  employments,
  expenses,
  expenseKinds,
  expenseGroups,
  expenseFrequencies,
  onAdd,
  onUpdate,
  onRemove,
  onSubmit,
  onCancel
}) {
  updatePageTitle('General expenses')

  const visibleExpenseGroups = expenseGroups.filter(expenseGroup => {
    if (!employments.length) {
      return true
    }

    if (expenseGroup.code === 'MOD') {
      return !!employments.find(e => e.mod_branch)
    } else if (expenseGroup.code === 'SELF-EMPLOYED') {
      return !!employments.find(e => e.self_employed)
    } else if (expenseGroup.code === 'GENERAL') {
      return !employments.find(e => e.mod_branch)
    }
    return true
  })

  const expenseGroupKinds = expenseGroup => {
    return expenseKinds.filter(k => k.expense_group_id === expenseGroup.id && k.active && !k.workplace)
  }

  const expensesForKind = expenseKindId => {
    return expenses.filter(ex => ex.expense_kind_id === expenseKindId)
  }

  return (
    <ContentWrapper modifiers="small">
      <ContentWrapperInner modifiers="longform">
        {visibleExpenseGroups.map((expenseGroup, expenseGroupIndex) => {
          const kindsForExpenseGroup = expenseGroupKinds(expenseGroup)
          const helperText =
            helperTextData.expenses[snakeCase(expenseGroup.code)]?.general[snakeCase(expenseGroup.heading)]

          return (
            <Fragment key={expenseGroup.code}>
              <FormGroup>
                {helperText && <Helper id={`helper-general-expenses-${expenseGroup.heading}`} body={helperText} />}

                <Question
                  heading={expenseGroup.heading}
                  headingContent={
                    helperText && (
                      <HelperTrigger
                        helperId={`helper-general-expenses-${expenseGroup.heading}`}
                        eventLabel={`the '${expenseGroup.heading}' question on the General Expenses Form`}
                      />
                    )
                  }
                />
                {kindsForExpenseGroup.map((expenseKind, kindIndex) => {
                  const previousExpanded = !!(
                    kindIndex && expensesForKind(kindsForExpenseGroup[kindIndex - 1].id).length > 1
                  )

                  return (
                    <ExpenseInput
                      expenses={expensesForKind(expenseKind.id)}
                      key={`${expenseGroup.code}-kind-${kindIndex}`}
                      expenseKindId={expenseKind.id}
                      first={!kindIndex || previousExpanded}
                      last={kindIndex === kindsForExpenseGroup.length - 1}
                      onAdd={onAdd}
                      onUpdate={onUpdate}
                      onRemove={onRemove}
                      frequencies={frequenciesForExpenseKind(expenseKind, expenseFrequencies)}
                      title={expenseKind.name}
                    />
                  )
                })}
              </FormGroup>
              {expenseGroupIndex !== visibleExpenseGroups.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </ContentWrapperInner>
      <ContentFooter modifiers="actions">
        <ButtonGroup modifiers="spread">
          <ButtonGroupItem buttonModifiers="default" element="anchor" href="#" onClick={onCancel}>
            Cancel
          </ButtonGroupItem>
          <ButtonGroupItem buttonModifiers="primary" element="anchor" href="#" onClick={onSubmit}>
            Save expenses
          </ButtonGroupItem>
        </ButtonGroup>
      </ContentFooter>
    </ContentWrapper>
  )
}

GeneralExpenseForm.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.object).isRequired,
  employments: PropTypes.arrayOf(PropTypes.object).isRequired,
  expenseKinds: PropTypes.arrayOf(PropTypes.object).isRequired,
  expenseGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  expenseFrequencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

import React from 'react'
import PropTypes from 'prop-types'

import WorkLocationForm from '../../interactive/WorkLocationForm'
import EmploymentForm from '../../interactive/EmploymentForm'
import VehicleForm from '../../interactive/VehicleForm'
import IdleHelpPanel from '../../interactive/IdleHelpPanel'

import {
  fetch,
  redirectTo,
  existsByProperty,
  highlightFirstError,
  mergeOrAddByProperty,
  values,
  isIterable
} from '../../../modules/utils'
import { updatePageTitle } from '../../../modules/legacyUtils'
import { fetchExpenseFrequencies } from '../../../modules/api'
import { getRangeDuration, parseDate } from '../../../modules/dates'
import { defaultStateForEmployment } from '../../../modules/workLocation'
import { showIdleHelpPanel, onInteractionWithForm } from '../../../modules/idleHelpPanel'
import { trackEvent } from '../../../modules/tracking'

class WorkLocationsNew extends React.Component {
  constructor(props) {
    super(props)

    const { modEmployee, personEmploymentId, publicTransportExpenseKinds, startedAt, endedAt } = props

    const defaultStartedAt = startedAt ? new Date(startedAt) : null
    const defaultEndedAt = endedAt ? new Date(endedAt) : null

    const rangeDuration = getRangeDuration(parseDate(defaultStartedAt), parseDate(defaultEndedAt, new Date()))

    this.state = {
      workLocation: {
        person_employment_id: personEmploymentId || null,
        person_vehicle_id: null,
        address_id: null,
        ministry_of_defence: modEmployee,
        started_at: defaultStartedAt,
        ended_at: defaultEndedAt,
        current: !endedAt,
        quantitative_frequency: modEmployee ? null : 5,
        qualitative_frequency: modEmployee ? null : 'Per week',
        expected_time_at_post: null,
        expected_time_at_post_years: modEmployee ? rangeDuration.years : null,
        expected_time_at_post_months: modEmployee ? rangeDuration.months : null,
        expected_time_at_post_weeks: modEmployee ? 0 : null,
        expected_time_at_post_changed_at: null,
        travel_home_frequency: null,
        travel_home_quantitative_frequency: !modEmployee ? null : 5,
        travel_home_qualitative_frequency: !modEmployee ? null : 'Per week',
        complex_routine: null,
        show_complex_routine: false,
        name: null,
        public_transport_expenses: [],
        received_reimbursements: !modEmployee ? null : false,
        reimbursements_quantity: null,
        address: {
          name: null,
          first_line: null,
          second_line: null,
          town: null,
          county: null,
          country: null,
          postcode: null,
          latitude: null,
          longitude: null
        },
        public_transport_options: {
          bus: false,
          train: false,
          tram: false,
          tube: false
        },
        transport: {
          own_vehicle: false,
          public_transport: false,
          car_share: false,
          company_vehicle: false,
          pickup_point: false,
          other: false
        }
      },
      addressLoaded: false,
      employmentId: null,
      employments: [],
      employmentsLoaded: false,
      errors: {},
      expenseFrequencies: [],
      publicTransportExpenseKinds: publicTransportExpenseKinds,
      showEmploymentForm: false,
      showVehicleForm: false,
      vehicleId: null,
      vehicles: [],
      vehiclesLoaded: false,
      workLocationLoaded: !props.id
    }

    this.showEmploymentForm = this.showEmploymentForm.bind(this)
    this.showVehicleForm = this.showVehicleForm.bind(this)
    this.showWorkLocationForm = this.showWorkLocationForm.bind(this)
    this.setWorkLocationState = this.setWorkLocationState.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  newRecord() {
    const { workLocation } = this.state
    return !workLocation.id
  }

  trackFilledInEvent(type) {
    if (this.newRecord()) {
      trackEvent(`Work location ${type} filled in`)
    }
  }

  showEmploymentForm(employmentId) {
    this.setState({
      employmentId,
      showEmploymentForm: true
    })
  }

  showVehicleForm(vehicleId) {
    this.setState({
      vehicleId,
      showVehicleForm: true
    })
  }

  showWorkLocationForm(e) {
    const newRecordCreated = e && !e.preventDefault

    if (!newRecordCreated) {
      e.preventDefault()
      return this.setState({
        showEmploymentForm: false,
        showVehicleForm: false
      })
    }

    // Merge or add the employment or vehicle to state to save a server
    // load:
    const record = e
    const isVehicle = !!record.vehicle
    const { vehicles, employments, workLocation } = this.state
    const model = isVehicle ? 'vehicles' : 'employments'
    const collection = isVehicle ? vehicles : employments
    const newRecord = !existsByProperty(collection, record.id, 'id')

    const nextState = {
      showEmploymentForm: false,
      showVehicleForm: false,
      [model]: mergeOrAddByProperty(collection, record, 'id')
    }

    // Sometimes we'll just be updating a record, in which case we don't want to
    // select it:
    if (newRecord) {
      if (isVehicle) {
        nextState['workLocation'] = {
          ...workLocation,
          person_vehicle_id: record.id
        }

        this.trackFilledInEvent('vehicle')
      } else {
        // employment
        nextState['workLocation'] = {
          ...workLocation,
          ...defaultStateForEmployment(record.id, record.ministry_of_defence)
        }

        this.trackFilledInEvent('employment')
      }
    }

    this.setState(nextState)
  }

  fetchErrors() {
    const { id } = this.props
    fetch('GET', `/work_locations/${id}/valid.json`).then(errors => {
      this.setState({ errors })
    })
  }

  fetchWorkLocation() {
    const { id } = this.props
    return fetch('GET', `/work_locations/${id}/edit.json`).then(workLocation => {
      this.setState({
        workLocation,
        workLocationLoaded: true
      })
    })
  }

  fetchEmployments() {
    fetch('GET', '/employments.json').then(employments =>
      this.setState({
        employments,
        employmentsLoaded: true
      })
    )
  }

  fetchVehicles() {
    fetch('GET', '/vehicles.json').then(vehicles =>
      this.setState({
        vehicles,
        vehiclesLoaded: true
      })
    )
  }

  selectedEmployment() {
    const { employments, employmentId } = this.state
    return employments.find(employment => employment.id == employmentId) || {}
  }

  selectedVehicle() {
    const { vehicles, vehicleId } = this.state
    return vehicles.find(vehicle => vehicle.id == vehicleId) || {}
  }

  componentDidMount() {
    const { id } = this.props

    this.fetchEmployments()
    this.fetchVehicles()
    fetchExpenseFrequencies().then(frequencies => this.setState({ expenseFrequencies: frequencies }))

    if (id) {
      this.fetchWorkLocation().then(() => {
        const { workLocation } = this.state
        if (!workLocation.valid) {
          this.fetchErrors()
          highlightFirstError()
        }
      })
    }
  }

  setWorkLocationState(workLocation, errors = {}) {
    this.setState({ workLocation, errors })
  }

  hasErrors() {
    const { errors } = this.state
    return !!values(errors).find(e => e && e.length)
  }

  handleCancel(e) {
    e.preventDefault()
    window.history.back()
  }

  handleSubmit(e) {
    e.preventDefault()

    const { authenticityToken, id, afterSubmitPath } = this.props
    const { workLocation } = this.state

    if (this.hasErrors()) {
      return highlightFirstError()
    }

    const requestData = {
      api_person_location: workLocation,
      utf8: '✓',
      authenticity_token: authenticityToken
    }

    const requestVerb = id ? 'PUT' : 'POST'
    const requestUri = id ? `/work_locations/${id}.json` : '/work_locations.json'

    return fetch(requestVerb, requestUri, 'application/json', null, requestData)
      .then(() => {
        return redirectTo(afterSubmitPath)
      })
      .catch(e => {
        if (isIterable(e)) {
          const [, data] = e
          this.setState({ errors: data.errors }, function () {
            highlightFirstError()
          })
        }
      })
  }

  // This just avoids excessive re-rendering of components while we wait for
  // The data we need to load from the rails server
  waitToRender() {
    const { workLocationLoaded, employmentsLoaded, vehiclesLoaded } = this.state
    return !workLocationLoaded || !employmentsLoaded || !vehiclesLoaded
  }

  getPageTitle() {
    const { id } = this.props
    const { showEmploymentForm, employmentId, showVehicleForm, vehicleId } = this.state

    if (showEmploymentForm) {
      return `${employmentId ? 'Edit' : 'New'} employer`
    }

    if (showVehicleForm) {
      return `${vehicleId ? 'Edit' : 'New'} vehicle`
    }

    return `${id ? 'Edit' : 'New'} workplace`
  }

  renderForm() {
    const { constructionEmployee, modEmployee, authenticityToken } = this.props
    const { showEmploymentForm, employments, workLocation, showVehicleForm, vehicles, errors } = this.state

    updatePageTitle(this.getPageTitle())

    if (showEmploymentForm) {
      return (
        <EmploymentForm
          onCancel={this.showWorkLocationForm}
          onAfterSubmit={this.showWorkLocationForm}
          resource={this.selectedEmployment()}
          modEmployee={modEmployee}
          constructionEmployee={constructionEmployee}
          authenticityToken={authenticityToken}
          locked={false}
          endedAtLocked={false}
        />
      )
    } else if (showVehicleForm) {
      return (
        <VehicleForm
          onCancel={this.showWorkLocationForm}
          onAfterSubmit={this.showWorkLocationForm}
          resource={this.selectedVehicle()}
          authenticityToken={authenticityToken}
          locked={false}
          endedAtLocked={false}
          onChange={() => onInteractionWithForm()}
        />
      )
    } else {
      return (
        <WorkLocationForm
          resource={workLocation}
          employments={employments}
          expenseFrequencies={this.state.expenseFrequencies}
          expenseKinds={this.state.publicTransportExpenseKinds}
          vehicles={vehicles.map(vehicle => vehicle.option_data)}
          onShowEmploymentForm={this.showEmploymentForm}
          onShowVehicleForm={this.showVehicleForm}
          onCancel={this.handleCancel}
          onChange={e => {
            onInteractionWithForm()
            this.setWorkLocationState(e)
          }}
          onSubmit={this.handleSubmit}
          errors={errors}
        />
      )
    }
  }

  render() {
    if (this.waitToRender()) {
      return null
    }

    return (
      <>
        {this.renderForm()}
        {showIdleHelpPanel(this.props.afterSubmitPath) && <IdleHelpPanel page="timelineWorkLocations" />}
      </>
    )
  }
}

WorkLocationsNew.propTypes = {
  constructionEmployee: PropTypes.bool.isRequired,
  modEmployee: PropTypes.bool.isRequired,
  personEmploymentId: PropTypes.number,
  startedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  afterSubmitPath: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  id: PropTypes.number
}

export default WorkLocationsNew
